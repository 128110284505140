import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  FormGroup,
  FormLabel,
  Dropdown,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getClassList, getSchoolCycleStudent } from "../../api/class";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import SeeAllModule from "./admin/SeeAllModule";
import { goToHref, userInfo, setUserRestriction, validateUserRoleIds } from "../global/GlobalTools";
import * as Sentry from "@sentry/react";
import { GetUserRelativeStudent } from "../../api/DashboardRelativeStudent";
import ModalSuccess from "../global/modals/ModalSuccess";

const heightWindow = window.innerHeight;
let maxHeightList = heightWindow - heightWindow / 2.4;
maxHeightList = maxHeightList - 190;

const customStyles = {
  lazy: {
    height: "25px",
  },
  scrollClass: {
    height: maxHeightList + "px",
    minHeight: "375px",
    overflowY: "auto",
    overflowX: "hidden",
    marginTop: "13px",
  },
  paddingContainerList: {
    paddingTop: "10px",
  },
  styleTopClass: {
    marginTop: "25px",
    marginBottom: "20px",
  },
};

const dummyClass = [
  {
    name: "Clase 1",
    nameTeacher: "LUIS MORALES",
    score: "8",
    attended: "5/30",
    attendedCompletes: 5,
    attemdedPending: 25,
  },
  {
    name: "Clase 1",
    nameTeacher: "LUIS MORALES",
    score: "8",
    attended: "5/30",
    attendedCompletes: 5,
    attemdedPending: 25,
  },
  {
    name: "Clase 1",
    nameTeacher: "LUIS MORALES",
    score: "8",
    attended: "5/30",
    attendedCompletes: 5,
    attemdedPending: 25,
  },
  {
    name: "Clase 1",
    nameTeacher: "LUIS MORALES",
    score: "8",
    attended: "5/30",
    attendedCompletes: 5,
    attemdedPending: 25,
  },
  {
    name: "Clase 1",
    nameTeacher: "LUIS MORALES",
    score: "8",
    attended: "5/30",
    attendedCompletes: 5,
    attemdedPending: 25,
  },
];

const lazyLoadingClass = () => {
  return (
    <div>
      <Row className="table-lazyLoading mt-3">
        <Col md={7}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={5}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-4">
        <Col md={3}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={3}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={3}>
          <div style={customStyles.lazy}></div>
        </Col>
        <Col md={3}>
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-4">
        <Col md={12}>
          <div style={customStyles.lazy}></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
          <div style={customStyles.lazy} className="mt-4"></div>
        </Col>
      </Row>
      <Row className="table-lazyLoading mt-4">
        <Col md={2} className="offset-md-10">
          <div style={customStyles.lazy}></div>
        </Col>
      </Row>
    </div>
  );
};

const ClassView = (props) => {
  const prefix = process.env.REACT_APP_PREFIX;
  const [t] = useTranslation(["dashboardStudent", "reports", "dashboard"]);
  const [classModule, setClassModule] = useState([]);
  const [optionsCycles, setOptionsCycles] = useState([]);
  const [cycleSelected, setCycleSelected] = useState(null);
  const [loadingClass, setLoadingClass] = useState(true);
  const [hasDebtRestriction, setHasDebtRestriction] = useState(false);
  const [modalSuccessMessage, setModalSuccessMessage] = useState("");
  const [modalSuccessTtile, setModalSuccessTitle] = useState("");
  const [txtBtnAccept, setTxtBtnAccept] = useState("");
  const [txtBtnCancel, setTxtBtnCancel] = useState("");
  const [openModalSuccess, setOpenModalSucces] = useState(false);

  /**
   *Gets the classes by cycle and by student id
   * @param {id} cycleId
   */
  const getClass = (cycleId) => {
    let request = {
      school_cycle_id: cycleId,
      student_id: props.studentId,
      additional_fields: "days_attended",
      // Set timezone
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    setHasDebtRestriction(false);
    getClassList(request)
      .then((result) => {
        if (result.status) {
          let data = result.data.data;
          let classes = [];
          classes = data.map((item) => {
            return {
              name: item.class_title,
              nameTeacher: item.teacher_name,
              score: item.score,
              attended: item.days_attended,
              attendedCompletes: item.days_attended.split("/")[0],
              attemdedPending:
                item.days_attended.split("/")[1] -
                item.days_attended.split("/")[0],
            };
          });
          setClassModule(classes);
        } else {
          if (result.code === "err_students_with_debt_restrictions") {
            setHasDebtRestriction(true);
          }
          Sentry.captureException(Error(JSON.stringify(result)));
          return;
        }
      })
      .catch()
      .finally(() => {
        setLoadingClass(false);
      });
  };

  /**
   * Gets the cycles per student
   */
  const getCycleStudent = () => {
    let request = {
      student_id: props.studentId,
    };
    getSchoolCycleStudent(request).then((result) => {
      if (result.status && result.data.school_cycles) {
        const cycles = result.data.school_cycles;
        cycles.forEach((element) => {
          element.value = element.id;
          element.label = element.school_cycle;
        });
        setOptionsCycles(cycles);
        const filterDashboard = JSON.parse(
          localStorage.getItem(`cmFiltersDashboard${prefix}`)
        );
        let cycleSelected = cycles[0];
        if (filterDashboard && filterDashboard.cycle) {
          let existCycle = cycles.find(
            (cycle) => cycle.id == filterDashboard.cycle.id
          );
          if (existCycle) {
            cycleSelected = existCycle;
          }
        }
        setCycleSelected(cycleSelected);
        if (cycleSelected && cycleSelected.id) {
          getClass(cycleSelected.id);
        } else {
          setLoadingClass(false);
        }
      }setLoadingClass(false);
    });
  };

  /**
   * Puporse: Get User Relative and Student info
   */
  const getUserRelativeStudent = () => {
    const user = userInfo();
    const request = { user_id: user.id };
    GetUserRelativeStudent(request).then((result) => {
      if (result.status) {
        const userInfo = result.data;
        //Storage of votes in localStorage
        localStorage.setItem(
          `cmUserVotes${prefix}`,
          JSON.stringify(userInfo.votes)
        );
        // Get Restriction
        let hasDebtRestriction = false;
        const students = userInfo.students || [];
        if (students && Array.isArray(students) && students.length !== 0) {
          for (let item of students) {
            hasDebtRestriction =
              item &&
              item.has_debt_restriction &&
              item.has_debt_restriction == 1
                ? true
                : false;
            if (hasDebtRestriction) {
              break;
            }
          }
        }
        // Set restriction
        setHasDebtRestriction(hasDebtRestriction);
        // Set Cookie
        setUserRestriction(hasDebtRestriction);
      }
    });
  };

  /**
   * Puporse: Displays the restriction modal
   */
  const showRestrictions = () => {
    setHasDebtRestriction(true);
    setModalSuccessMessage(t("global:modalAlertClassesRestrictions.message"));
    setModalSuccessTitle(t("global:modalAlertClassesRestrictions.title"));
    setTxtBtnAccept(t("reports:paymentsTabs.statementAccount"));
    setTxtBtnCancel(t("global:buttons.close"));
    setOpenModalSucces(true);
  };

  /**   * initial effect of a single load   */
  useEffect(() => {
    if (props.active == false) {
      setClassModule(dummyClass);
      setLoadingClass(false);
    } else if (props.loadClassModule) {
      Sentry.setTag("section", "Class Module");
      if (!hasDebtRestriction) {
        getCycleStudent();
      } else {
        setLoadingClass(false);
      }
    }
    if (validateUserRoleIds([6,8])) {
      getUserRelativeStudent();
    }
  }, [props.active, props.loadClassModule]);

  useEffect(() => {
    if (props.refreshClasses) {
      setLoadingClass(true);
      setClassModule([]);
      getCycleStudent();
      props.setRefreshClasses(false);
    }
  }, [props.refreshClasses]);

  return (
    <Row>
      <Col>
        {loadingClass ? (
          lazyLoadingClass()
        ) : (
          <>
            <Row>
              <Col xs={12} md={8} xl={8} className="mt-3">
                <i className="bi bi-journals me-2 fs-6"></i>
                <b className="fs-6">{t("textClassView.titleClass")}</b>
              </Col>
              <Col xs={12} md={4} className="mt-3">
                <FormGroup className="float-end">
                  <FormLabel className="mb-0">
                    {t("reports:filterSelectSchoolCycle.schoolCycle")}{" "}
                  </FormLabel>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="cycleSchool"
                      variant="outline-secondary"
                      className="text-truncate"
                      disabled={hasDebtRestriction}
                    >
                      {cycleSelected?.label}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {optionsCycles.map((item) => {
                        return (
                          <Dropdown.Item
                            key={item}
                            onClick={() => {
                              setCycleSelected(item);
                              getClass(item.id);
                              const filterDashboard = JSON.parse(
                                localStorage.getItem(
                                  `cmFiltersDashboard${prefix}`
                                )
                              );
                              const filters = filterDashboard || {};
                              filters.cycle = item;
                              localStorage.setItem(
                                `cmFiltersDashboard${prefix}`,
                                JSON.stringify(filters)
                              );
                            }}
                            className="text-truncate"
                          >
                            {item.label}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4 mb-4">
              <Col md={12} className="px-4">
                <Row>
                  <Col md={3} className="text-truncate">
                    {t("textClassView.textClass")}
                  </Col>
                  <Col md={4} className="text-truncate">
                    {t("textClassView.textTeacher")}
                  </Col>
                  <Col md={2} className="text-truncate">
                    {t("textClassView.textScore")}
                  </Col>
                  <Col md={3} className="text-truncate">
                    {t("textClassView.textAssistance")}
                  </Col>
                </Row>
              </Col>
            </Row>
            {classModule.length ? (
              <div style={customStyles.scrollClass}>
                {classModule.map((item) => {
                  return (
                    <Row key={item} className="mb-3">
                      <Col xs={12} md={12} xl={12} className="px-4">
                        <Row
                          className="row-body"
                          style={customStyles.paddingContainerList}
                        >
                          <Col xs={3} sm={3} md={3} className="text-truncate">
                            {item.name}
                          </Col>
                          <Col xs={4} sm={4} md={4} className="text-truncate">
                            {item.nameTeacher}
                          </Col>
                          <Col xs={2} sm={2} md={2}>
                            {item.score}
                          </Col>
                          <Col xs={3} sm={3} md={3}>
                            <Row>
                              <Col md={4} className="d-inline">
                                <Doughnut
                                  data={{
                                    datasets: [
                                      {
                                        data: [
                                          item.attendedCompletes,
                                          item.attemdedPending,
                                        ],
                                        backgroundColor: [
                                          "rgba(255, 99, 132, 1)",
                                          "rgba(235, 235, 235, 1)",
                                        ],
                                        borderColor: [
                                          "rgba(255, 99, 132, 1)",
                                          "rgba(235, 235, 235, 1)",
                                        ],
                                        borderWidth: 1,
                                      },
                                    ],
                                  }}
                                  options={{
                                    responsive: false,
                                    tooltips: {
                                      enabled: false,
                                    },
                                  }}
                                  legend={{
                                    display: false,
                                    fullWidth: true,
                                    position: "bottom",
                                    align: "center",
                                    labels: {
                                      usePointStyle: false,
                                    },
                                  }}
                                  height="30px"
                                  width="30px"
                                />
                              </Col>
                              <Col md={8} className="d-inline">
                                {item.attended}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            ) : hasDebtRestriction ? (
              <Row>
                <Col md={12}>
                  <p className="text-center">
                    <b>{t("global:modalAlertClassesRestrictions.title")}</b>
                  </p>
                  <p className="text-center">
                    {t("global:modalAlertClassesRestrictions.message")}
                  </p>
                </Col>
                <Col sm={12}>
                  <Button
                    style={{
                      position: "absolute",
                      marginTop: "20px",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    variant="primary"
                    onClick={() => {
                      hasDebtRestriction && validateUserRoleIds([6,8])
                        ? showRestrictions()
                        : goToHref("/payments/accountStatus");
                    }}
                  >
                    {t("reports:paymentsTabs.statementAccount")}
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md={12}>
                  <p className="text-center">
                    {t("dashboard:textTask.titleNoTask")}
                  </p>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12} style={customStyles.styleTopClass}>
                {classModule.length > 0 && cycleSelected && (
                  <SeeAllModule
                    path={`class?studentId=${props.studentId}&cycleId=${cycleSelected.id}`}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
      </Col>

      {openModalSuccess && (
        <ModalSuccess
          showModalSuccess={openModalSuccess}
          size="md"
          title={modalSuccessTtile}
          message={modalSuccessMessage}
          txtBtnAccept={txtBtnAccept}
          fnAcceptButton={() => goToHref("/payments/accountStatus")}
          fnCancelButton={() => setOpenModalSucces(false)}
          txtBtnCancel={txtBtnCancel}
        />
      )}
    </Row>
  );
};

export default ClassView;

ClassView.propTypes = {
  studentId: PropTypes.number,
  active: PropTypes.bool,
  refreshClasses: PropTypes.func,
  setRefreshClasses: PropTypes.func,
  hasDebtRestriction: PropTypes.bool,
  loadClassModule: PropTypes.bool,
};
