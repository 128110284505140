import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Dropdown,
  InputGroup,
  DropdownButton,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import ReactToPrint from "react-to-print";
import ModalConfigurePaymentReminder from "./modals/ModalConfigurePaymentReminder";
import ModalSendPaymentReminder from "./modals/ModalSendPaymentReminder";
import ModalAccountStatus from "./modals/ModalAccountStatus";
import { MainContext } from "../../App";
import {
  getUrlParamValue,
  goToHref,
} from "../../components/global/GlobalTools";
import * as Sentry from "@sentry/react";

/** Services */
import { GetDebts, saveManulDebtsRestrictions } from "../../api/PaymentsReport";
import { GetReminder } from "../../api/Reminder";
import { GetAccountStatus } from "../../api/accountStatus";

/** Elements */
import { Table } from "../../components/table/Table";
import ContentTable from "../../components/table/ContentTable";
import ContentSolid from "../../components/global/ContentSolid";
import SelectFilter from "../../components/table/SelectFilter";
import DateRangeFilter from "../../components/table/DateRangeFilter";
import { LoadingTable } from "../../components/lazyLoading/LazyLoading";
import { thousandsFormat } from "../../components/global/GlobalTools";
import ExportToExcel from "../../components/table/ExportToExcel";
import InputFilter from "../../components/table/InputFilter";
import Label from "../../components/table/Label";
import ModalSetRestrictions from "./modals/ModalSetRestrictions";
import ModalSuccess from "../../components/global/modals/ModalSuccess";
import ModalRestrictAccess from "./modals/ModalRestrictAccess";
//Custom styles section
const customStyles = {
  textDangerBold: {
    color: "#ea2c54",
    fontWeight: "bold",
    fontSize: "small",
  },
  textGrayBold: {
    color: "#c8cbcc",
    fontWeight: "bold",
    fontSize: "small",
  },
};

/**Get param to opem send reminder modal */
const sendReminder = getUrlParamValue("sendReminder");
/**Get param to open configure reminder modal */
const configureReminder = getUrlParamValue("configureReminder");
const DebtReportView = () => {
  const componentRef = useRef(); //Create the reference for Printing
  const { permissions } = useContext(MainContext);
  const [t] = useTranslation(["reports", "global", "table"]); //In the array that is passed as an argument in useTranslation, the translations are loaded per module
  const [dataTable, setDataTable] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingView, setLoadingView] = useState(true);
  const [exportData, setExportData] = useState([]);
  const [printExport, setPrintExport] = useState(true);
  const [showEditColumns, setShowEditColumns] = useState(false);
  const [showConfigureReminder, setShowConfigureReminder] =
    useState(configureReminder);
  const [showSendReminder, setShowSendReminder] = useState(sendReminder);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [reminder, setReminder] = useState({});
  const [isSavingReminder, setIsSavingReminder] = useState(false);
  const [reopenModalSendReminder, setReopenModalSendReminder] = useState(false);
  const [values, setValues] = useState([]);
  const [numberStudents, setNumberStudents] = useState(0);
  const [showAccountStatus, setShowAccountStatus] = useState(false);
  const [dataAccountStatus, setDataAccountStatus] = useState("");
  const [openModalSetConfigurations, setOpenModalSetConfigurations] =
    useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [selectAllRows, setSelectAllRows] = useState({
    switch: false,
    value: false,
  });
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [count, setCount] = useState(0);

  //Options to export to excel
  const exportOptions = {
    openAsDownload: true,
    format: "xlsx", //'xlsx' or 'xls' or 'csv'
    filename: t("report"),
  };
  const [openModalRestrictAccess, setOpenModalRestrictAccess] = useState(false);
  const [modalSuccessTitle, setModalSuccessTitle] = useState("");
  const [modalSuccessMessage, setModalSuccessMessage] = useState("");
  const [modalSuccessSize, setModalSuccessSize] = useState("md");
  const [acceptButtonVariant, setAcceptButtonVariant] =
    useState("outline-secondary");
  const [cancelModalSuccessFunction, setCancelModalSuccessFunction] =
    useState(false);
  const [selectedRecords, setSelectedRecords] = useState({
    maanual_restriction: "",
    charge_ids: [],
  });
  const [txtBtnAccept, setTextBtnAccept] = useState(t("global:buttons.close"));
  const [deleteAllRestrictions, setDeleteAllRestrictions] = useState(false);
  const [keepSelection, setKeepSelection] = useState(false);

  /**
   * Purpose: Get the reminder information
   */
  const getReminder = () => {
    GetReminder().then((result) => {
      if (result.data) {
        let reminder = {};
        reminder.title = result.data.title;
        reminder.description = result.data.text;
        setReminder(reminder);
      } else {
        Sentry.captureException(Error(JSON.stringify(result)));
      }
    });
  };

  /**
   * Purpose: Gets the data for the table
   * @param {Object} dates  start_date | end_date
   */
  const getDebts = (dates = {}) => {
    setSelectedRowIds([]);
    setLoading(true);
    // Set timezone
    dates.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    GetDebts(dates).then((result) => {
      if (result.description) {
        Sentry.captureException(Error(JSON.stringify(result)));
        return;
      }
      let data = result.data.data;
      for (let i = 0; i < data.length; i++) {
        const hasExpirationDate = Boolean(data[i].end_date);
        const daysLate = hasExpirationDate
          ? moment().endOf("day").diff(moment.utc(data[i].end_date), "days")
          : 0;
        data[i].surchargeFormat = `$ ${thousandsFormat(data[i].surcharge)}`;
        data[i].scholarshipFormat =
          data[i].scholarship == 0.0
            ? ` $ ${thousandsFormat(data[i].scholarship)}`
            : `-$ ${thousandsFormat(data[i].scholarship)}`;
        data[i].amountFormat = `$ ${thousandsFormat(data[i].amount)}`;
        data[i].previousPaidFormat = `$ ${thousandsFormat(
          data[i].previous_paid
        )}`;
        data[i].totalFormat = `$ ${thousandsFormat(data[i].total)}`;
        data[i].debtFormat = `$ ${thousandsFormat(data[i].debt)}`;

        data[i].index = data[i].id;
        data[i].daysLate = daysLate > 0 ? daysLate : 0;
        data[i].end_date = hasExpirationDate
          ? moment.utc(data[i].end_date).local().format("DD/MM/YYYY")
          : t("status.withoutDate");
        data[i].restriction_type = data[i].restriction_type || "-";
      }
      setDataTable(data);
      setLoading(false);
      setLoadingView(false);
      setNumberStudents(data.length);
    });
  };
  //Saves the data for the table in memory, and is not updated unless useMemo identifies a change in the data
  const data = React.useMemo(() => dataTable, [loading]);

  const columns = React.useMemo(
    () => [
      {
        Header: t("headerTable.internalEnrollment"), //Header text
        accessor: "unique_id", //accessor is the "key" in the data
        minWidth: dataTable.length && count != 0 ? 110 : 130,
        width: 140,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.name"),
        accessor: "student",
        minWidth: 50,
        width: 100,
        maxWidth: 120,
      },
      {
        Header: t("filterSelectSchoolCycle.schoolCycle"),
        accessor: "school_cycle",
        Filter: SelectFilter,
        filter: 'equals', // Strict filtering
        minWidth: 50,
        width: 100,
        maxWidth: 120,
      },
      {
        Header: t("headerTable.schoolLevel"),
        accessor: "school_level",
        Filter: SelectFilter, //Filter: InputFilter | SelectFilter | DateRangeFilter (InputFilter is the default filter)
        minWidth: 50,
        width: 100,
        maxWidth: 110,
      },
      {
        Header: t("headerTable.grade"),
        accessor: "grade",
        Filter: SelectFilter,
        minWidth: 20,
        width: 100,
        maxWidth: 120,
      },
      {
        Header: t("headerTable.daysLate"),
        accessor: "daysLate",
        Filter: (props) => <InputFilter {...props} />,
        typeElement: "label",
        Cell: ({ cell: { value }, row: { original } }) => {
          return badgeStatus(value, original.expiration_status);
        },
        minWidth: 50,
        width: 120,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.restrictionType"),
        accessor: "restriction_type",
        minWidth: 50,
        width: 130,
        maxWidth: 140,
      },
      {
        Header: t("headerTable.expiration"),
        accessor: "end_date",
        Filter: (props) => (
          <DateRangeFilter
            getByDateRange={getDebts}
            lastNDays={{
              start_date: moment().subtract(30, "day").startOf("day").unix(),
              end_date: moment().endOf("day").unix(),
              label: t("status.lastThirtyDay"),
            }}
            extraOptions={[
              {
                label: t("status.withoutDate"),
                value: 9,
                start_date: 0,
                end_date: 0,
              },
              {
                label: t("status.allFeminine"),
                value: 10,
                start_date: null,
                end_date: null,
                isDefault: true,
              },
            ]}
            {...props}
            futureDatesSelectables={true} //To allow selecting future dates
          />
        ),
        filter: "dateBetween",
        minWidth: 50,
        width: 120,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.concept"),
        accessor: "concept_name",
        minWidth: 50,
        width: 80,
        maxWidth: 120,
      },
      {
        Header: t("headerTable.subconcept"),
        accessor: "subconcept_name",
        minWidth: 50,
        width: 120,
        maxWidth: 150,
      },
      {
        Header: t("headerTable.charge"),
        accessor: "amountFormat",
        Summation: (info) => {
          // Calculate summation of amount if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.original.amount) + sum,
                0
              ),
            [info.rows]
          );
          return `$ ${thousandsFormat(total)}`;
        },
        propertySummation: "amount",
        minWidth: 80,
        width: 100,
        maxWidth: 120,
      },
      {
        Header: t("headerTable.surcharge"),
        accessor: "surchargeFormat",
        Summation: (info) => {
          // Calculate summation of surcharge if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.original.surcharge) + sum,
                0
              ),
            [info.rows]
          );
          return `$ ${thousandsFormat(total)}`;
        },
        propertySummation: "surcharge", //Indicates the property in the "row.original" array to be added
        minWidth: 80,
        width: 150,
        maxWidth: 180,
      },
      {
        Header: t("headerTable.scholarship"),
        accessor: "scholarshipFormat",
        Summation: (info) => {
          // Calculate summation of amount if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.original.scholarship) + sum,
                0
              ),
            [info.rows]
          );
          return total == 0.0 ? (
            <span style={{ color: "#53B567" }}>$ {thousandsFormat(total)}</span>
          ) : (
            <span style={{ color: "#53B567" }}>
              {" "}
              -$ {thousandsFormat(total)}
            </span>
          );
        },
        propertySummation: "scholarship",
        Cell: ({ cell: { value } }) => {
          return <span style={{ color: "#53B567" }}>{value}</span>;
        },
        minWidth: 80,
        width: 150,
        maxWidth: 180,
      },
      {
        Header: t("headerTable.total"),
        accessor: "totalFormat",
        disableSortBy: true,
        Summation: (info) => {
          // Calculate summation of total if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.original.total) + sum,
                0
              ),
            [info.rows]
          );
          return `$ ${thousandsFormat(total)}`;
        },
        propertySummation: "total",
        minWidth: 80,
        width: 150,
        maxWidth: 180,
      },
      {
        Header: t("headerTable.previousPaid"),
        accessor: "previousPaidFormat",
        Summation: (info) => {
          // Calculate summation of previous paid if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.original.previous_paid) + sum,
                0
              ),
            [info.rows]
          );
          return `$ ${thousandsFormat(total)}`;
        },
        propertySummation: "previous_paid",
        minWidth: 80,
        width: 150,
        maxWidth: 180,
      },
      {
        Header: t("headerTable.debt"),
        accessor: "debtFormat",
        Summation: (info) => {
          // Calculate summation of debts if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum, row) => parseFloat(row.original.debt) + sum,
                0
              ),
            [info.rows]
          );
          return `$ ${thousandsFormat(total)}`;
        },
        propertySummation: "amount",
        minWidth: 80,
        width: 150,
        maxWidth: 180,
      },
    ],
    [loading]
  );

  const badgeStatus = (value, status) => {
    const typeLabel = {
      expired: "danger",
      today: "warning",
      no_expired: "success",
      never_expires: "secondary",
    };
    const textLabel = {
      expired: `${value} ${value > 1 ? t("daysLate") : t("dayLate")}`,
      today: t("expiresToday"),
      no_expired: t("noLate"),
      never_expires: t("notApplicable"),
    };
    value = textLabel[status];
    return (
      <Label
        values={`${value}`}
        bg={typeLabel[status]}
        style={{ width: "100%" }}
      />
    );
  };

  const openConfigureReminder = (reopenModalSendReminder) => {
    setShowSendReminder(false);
    setShowConfigureReminder(true);
    setReopenModalSendReminder(reopenModalSendReminder);
  };

  /**Initial loading */
  useEffect(() => {
    Sentry.setTag("section", "Debt");
    getDebts();
  }, []);

  /**Initial loading */
  useEffect(() => {
    getReminder();
  }, [isSavingReminder]);

  /*
   * Description: Effect to know if all debt are selected
   */
  useEffect(() => {
    let selectAll = {
      switch: selectAllRows.switch,
      value: isAllRowsSelected,
    };
    setSelectAllRows(selectAll);
  }, [selectedRowIds]);

  /**
   * Purpose: Get the account status information
   */
  const showAccountStatusModal = (rowRecord = null) => {
    let studentId = rowRecord ? rowRecord.student_id : rowRecord;
    setShowAccountStatus(!showAccountStatus);
    if (rowRecord) getAccountStatus(studentId);
  };

  /**
   * Get account status data
   * @param {id} studentId
   */
  const getAccountStatus = (studentId) => {
    if (studentId) {
      GetAccountStatus(studentId).then((result) => {
        if (result.description) {
          Sentry.captureException(Error(JSON.stringify(result)));
          return;
        }
        //Get full name from student
        const student = result.data.student;
        const firstName = student.first_name || "";
        const middleName = student.middle_name || "";
        const lastName = student.last_name || "";
        result.data.fullStudentName = `${firstName} ${middleName} ${lastName}`;
        result.data.studentId = studentId;
        setDataAccountStatus(result.data);
      });
    } else {
      setDataAccountStatus("");
    }
  };

  /**
   * Puporse: Process selected elements
   */
  const restrictAccess = () => {
    // Obtaining records with Full restriction
    const totalRestriction = selectedRowIds.filter(
      (item) =>
        item.original.restriction_type == "Acceso Total" ||
        item.original.restriction_type == "Full Access"
    );
    // Obtaining records with partial restriction
    const partialRestriction = selectedRowIds.filter(
      (item) =>
        item.original.restriction_type == "Clases y Boletas" ||
        item.original.restriction_type == "Classes and Grades"
    );
    // Show warning modal if there are elements of both constraints
    if (
      totalRestriction &&
      totalRestriction.length !== 0 &&
      partialRestriction &&
      partialRestriction.length !== 0
    ) {
      setModalSuccessMessage(
        t("global:modalRemoveRestriction.notSelectRecords")
      );
      setModalSuccessTitle(t("global:buttons.restrict_access"));
      setTextBtnAccept(t("global:buttons.close"));
      setKeepSelection(true);
      setOpenModalSuccess(true);
    } else {
      const status =
        totalRestriction && totalRestriction.length !== 0
          ? "total_restriction"
          : partialRestriction && partialRestriction.length !== 0
          ? "partial_restriction"
          : "";
      // Obtain debts with a due date
      setSelectedRecords({
        maanual_restriction: status,
        charge_ids: selectedRowIds
          .filter((item) => item.original.end_date !== t("status.withoutDate"))
          .map((obj) => {
            return obj.original.id;
          }),
      });
      setOpenModalRestrictAccess(true);
    }
  };

  /**
   * Puporse: Remove restrictions
   */
  const modalSuccessFnAceptButton = () => {
    // Removing restriction
    if (deleteAllRestrictions) {
      setSelectedRowIds([]);
      const values = {
        maanual_restriction: "none",
        charge_ids: selectedRecords.charge_ids,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      saveManulDebtsRestrictions(values)
        .then((result) => {
          if (result && result.data && result.data.data) {
            setSelectedRecords({
              maanual_restriction: "",
              charge_ids: [],
            });
            getDebts();
          } else {
            Sentry.captureException(Error(JSON.stringify(result)));
          }
        })
        .catch(() => setSelectedRowIds([]))
        .then(() => {
          setSelectedRowIds([]);
          setOpenModalSuccess(false);
        });
    }
    if (!keepSelection) {
      setSelectedRowIds([]);
    }
    setOpenModalSuccess(false);
    setCancelModalSuccessFunction(false);
  };

  return permissions.payments_debts.access ? (
    <div>
      <Row className="mbottom20">
        <Col xs={12} lg={12} style={{ position: "relative" }}>
          <Button
            className="me-2"
            variant="outline-secondary"
            onClick={() => {
              let selectAll = {
                switch: !selectAllRows.switch,
                value: !selectAllRows.value,
              };
              setSelectAllRows(selectAll);
            }}
          >
            {!isAllRowsSelected
              ? t("global:buttons.selectAll")
              : t("global:buttons.unselectAll")}
          </Button>
          {/* Total counter of records and selected records in Debit Report table */}
          {selectedRowIds.length ? (
            selectedRowIds.length == 1 ? (
              <p className="d-inline" style={customStyles.textDangerBold}>
                {t("table:textTableViews.selectedRecord", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            ) : (
              <p className="d-inline" style={customStyles.textDangerBold}>
                {t("table:textTableViews.selectedRecords", {
                  countSelected: selectedRowIds.length,
                })}
              </p>
            )
          ) : (
            <p className="d-inline" style={customStyles.textGrayBold}>
              {t("table:textTableViews.totalRecords", {
                count: count,
              })}
            </p>
          )}
          <div className="float-end">
            <Dropdown className="d-md-inline-block me-2">
              <Dropdown.Toggle
                id="dropdown-button-dark-example1"
                variant="outline-secondary"
                disabled={printExport}
              >
                <i className="bi bi-download"></i>
                {t("global:buttons.download")}
              </Dropdown.Toggle>
              <Dropdown.Menu disabled={printExport}>
                <ReactToPrint
                  trigger={() => (
                    <Dropdown.Item>{t("global:buttons.print")}</Dropdown.Item>
                  )}
                  content={() => componentRef.current}
                />
                <ExportToExcel
                  exportData={exportData}
                  exportOptions={exportOptions}
                  printExport={printExport}
                  typeElement="dropdownItem"
                />
              </Dropdown.Menu>
            </Dropdown>
            {/** Section to restrict access */}
            {permissions.payments_debts_restrictions.access && (
              <Dropdown
                style={{ display: "inline-block", marginRight: "10px" }}
              >
                <Dropdown.Toggle
                  id="dropdown-button-dark-example1"
                  variant="outline-secondary"
                >
                  {t("global:buttons.restrictions")}
                </Dropdown.Toggle>
                {/**Drop down options */}
                <Dropdown.Menu>
                  <Dropdown.Item
                    disabled={printExport || selectedRowIds.length == 0}
                    onClick={restrictAccess}
                  >
                    {t("global:buttons.restrict_access")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    disabled={selectedRowIds.length !== 0}
                    onClick={() => setOpenModalSetConfigurations(true)}
                  >
                    {t("global:buttons.set_restrictions")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <div className="d-md-inline-block">
              <InputGroup>
                <a
                  href="?sendReminder=true"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="primary"
                    onClick={() => setShowSendReminder(true)}
                  >
                    <i className="bi bi-send"></i>
                    {t("reminder.buttonSend")}
                  </Button>
                </a>

                <DropdownButton
                  variant="primary"
                  title=""
                  id="input-group-dropdown-2"
                  align="end"
                >
                  <Dropdown.Item
                    href="?configureReminder=true"
                    onClick={() => setShowConfigureReminder(true)}
                  >
                    {t("reminder.buttonConfigure")}
                  </Dropdown.Item>
                </DropdownButton>
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>

      {loadingView ? (
        <ContentSolid style={{ marginTop: "20px" }}>
          <LoadingTable />
        </ContentSolid>
      ) : (
        <>
          {/** Table */}
          {/*The startColumFilter and lastColumFilter props indicates in which column the box with the magnifying glass should appear in the filters*/}
          <ContentTable startColumFilter={1} lastColumFilter={10}>
            <Table
              ref={componentRef}
              columns={columns}
              data={data}
              loading={loading}
              rowSelect={true}
              summation={true} //Indicates if there will be a row of sums
              setExportData={setExportData}
              setPrintExport={setPrintExport}
              hiddenColumns={[]} //Indicates the columns that will be hidden by default (The array contains the "accessors" of the columns)
              showEditColumns={showEditColumns} //Flag for the modal "Edit List Columns"
              setShowEditColumns={setShowEditColumns} //Toggle function of the "Edit List Columns" modal
              setSelectedRowIds={setSelectedRowIds}
              setValues={setValues}
              rowOnclick={showAccountStatusModal} //Function that is executed when clicking on a row
              selectAllRows={selectAllRows}
              setIsAllRowsSelected={setIsAllRowsSelected}
              setRowsDisplayed={setCount}
            />
          </ContentTable>
        </>
      )}

      <ModalConfigurePaymentReminder
        showConfigureReminder={showConfigureReminder}
        reminder={reminder}
        isSavingReminder={isSavingReminder}
        reopenModalSendReminder={reopenModalSendReminder}
        setShowConfigureReminder={setShowConfigureReminder}
        setShowSendReminder={setShowSendReminder}
        setIsSavingReminder={setIsSavingReminder}
      />

      <ModalSendPaymentReminder
        showSendReminder={showSendReminder}
        reminder={reminder}
        selectedRowIds={selectedRowIds}
        values={values}
        numberStudents={numberStudents}
        setShowSendReminder={setShowSendReminder}
        setShowConfigureReminder={setShowConfigureReminder}
        openConfigureReminder={openConfigureReminder}
        setReopenModalSendReminder={setReopenModalSendReminder}
        setSelectedRowIds={setSelectedRowIds}
        setNumberStudents={setNumberStudents}
      />
      <ModalAccountStatus
        showAccountStatus={showAccountStatus}
        dataAccountStatus={dataAccountStatus}
        showAccountStatusModal={showAccountStatusModal}
      />
      {/** Set Configurations Modal View */}
      {permissions.payments_debts_restrictions.access && (
        <>
          {/** Save configurations */}
          <ModalSetRestrictions
            open={openModalSetConfigurations}
            close={(value, saved) => {
              setOpenModalSetConfigurations(value);
              setOpenModalSuccess(saved);
            }}
            modalSuccessTitle={setModalSuccessTitle}
            modalSuccessMessage={setModalSuccessMessage}
            getDebts={getDebts}
          />
          {/**Sucess Save Configuration */}
          <ModalSuccess
            showModalSuccess={openModalSuccess}
            size={modalSuccessSize}
            title={modalSuccessTitle}
            message={modalSuccessMessage}
            txtBtnAccept={txtBtnAccept}
            fnAcceptButton={modalSuccessFnAceptButton}
            acceptButtonVariant={acceptButtonVariant}
            fnCancelButton={
              !cancelModalSuccessFunction
                ? ""
                : () => {
                    setDeleteAllRestrictions(true);
                    setOpenModalSuccess(false);
                  }
            }
          />
          {/** Save Custom Restriction */}
          <ModalRestrictAccess
            open={openModalRestrictAccess}
            close={(value, saved, cleanState) => {
              if (cleanState) {
                setSelectedRowIds([]);
              }
              setOpenModalSuccess(saved);
              setOpenModalRestrictAccess(value);
            }}
            modalSuccessTitle={setModalSuccessTitle}
            modalSuccessMessage={setModalSuccessMessage}
            selectedRowIds={selectedRowIds}
            setModalSuccessSize={setModalSuccessSize}
            setAcceptButtonVariant={setAcceptButtonVariant}
            setCancelModalSuccessFunction={setCancelModalSuccessFunction}
            getDebts={getDebts}
            selectedRecords={selectedRecords}
            setTextBtnAccept={setTextBtnAccept}
            setDeleteAllRestrictions={setDeleteAllRestrictions}
            setSelectedRowIds={setSelectedRowIds}
            setKeepSelection={setKeepSelection}
          />
        </>
      )}
    </div>
  ) : (
    goToHref("/landing_page")
  );
};

export default DebtReportView;
DebtReportView.propTypes = {
  cell: PropTypes.object,
  row: PropTypes.object,
};
